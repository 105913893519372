import * as firebase from "firebase/app";
import "firebase/storage";

import { locationCodename, firebaseConfig } from '../../constants';
import { Applicant } from "./Applicant";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
const storage = firebase.storage();

const getProcessedApplicantData = async (applicants): Promise<Applicant[]> => Promise.all(applicants.map(async (applicant) => {
    const { uniqueid, location, timestamp, files } = applicant;
    let fileURLs: string[];
    if (!files) {
        await storage.ref(uniqueid).list().then(async (fileList) => {
            fileURLs = await Promise.all(
                fileList.items.map(async (fileReference) => {
                    const downloadUrl = await fileReference.getDownloadURL();
                    return downloadUrl as string;
                })
            )
        });
    } else {
        fileURLs = await Promise.all((files as string[]).map(async (filePath) => {
            const fileRef = await storage.ref(filePath).getDownloadURL() as string;
            return fileRef;
        }))
    }
    const updatedData = {
        ...applicant,
        locationCode: location,
        location: locationCodename[location],
        files: fileURLs?.join('\n') || [],
        registerTime: new Date(timestamp),
    };
    delete updatedData.id;
    delete updatedData.uniqueid;
    delete updatedData.createdTime;
    delete updatedData.viewed;
    delete updatedData.timestamp;
    return updatedData as Applicant;
}))

export default getProcessedApplicantData;
