import { Workbook } from 'exceljs';
import reformatSheet from './reformatSheet';
import generateSheet from './generateSheet';
import initializeSheetColumns from './initializeSheetColumns';
import { Applicant } from './Applicant';

const addApplicantsToSheet = (workbook: Workbook, sheetName: string, applicants: Applicant[]) => {
    const sheet = generateSheet(workbook, sheetName);
    initializeSheetColumns(sheet);
    const locationApplicants = applicants ?? [];
    // Add applicant data to worksheet
    locationApplicants.forEach((applicant) => {
        sheet.addRow(applicant);
    });
    reformatSheet(sheet);
}

export default addApplicantsToSheet;
