// in src/posts.js
import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/storage";
import { Show, SimpleShowLayout, TextField, FunctionField, EmailField } from 'react-admin';
import { CircularProgress, Link, Typography } from "@material-ui/core";
import { firebaseConfig, locationCodename } from './constants';


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

const FilesDisplay = ({ source, record }: any) => {
    const uniqueID = record[source];
    const userFiles = record?.files as string[];

    const [applicantFiles, setApplicantFiles] = React.useState<{ name: string; downloadUrl: string }[]>(null);

    React.useEffect(() => {
        if (userFiles) {
            Promise.all(
                userFiles.map(async (filePath) => {
                    const fileReference = storage.ref(filePath);
                    const downloadUrl = await fileReference.getDownloadURL();
                    return ({
                        name: fileReference.name,
                        downloadUrl,
                    });
                })
            ).then((downloadUrlList) => {
                setApplicantFiles(downloadUrlList);
            })
        } else {
            storage.ref(uniqueID).list().then((fileList) => {
                Promise.all(
                    fileList.items.map(async (fileReference) => {
                        const downloadUrl = await fileReference.getDownloadURL();
                        return ({
                            name: fileReference.name,
                            downloadUrl,
                        });
                    })
                ).then((downloadUrlList) => {
                    setApplicantFiles(downloadUrlList);
                })
            });
        }
    }, [uniqueID, userFiles]);

    if (!applicantFiles) {
        return <CircularProgress />
    }

    return (
        <>
            <Typography component="div" variant="caption">
                List of Uploaded Files
            </Typography>
            {applicantFiles.map(({ name, downloadUrl }) => (
                <>
                    <Link key={downloadUrl} href={downloadUrl} target="_blank" rel="noopener noreferrer">{name}</Link>
                    <br />
                </>
            ))}
        </>
    );
}

const ApplicantView = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phonenumber" label="Phone Number" />
            <TextField source="job" />
            <FunctionField label="Location"  render={record => locationCodename[record.location]} />
            <FunctionField label="Applied Time"  render={record => new Date(parseInt(record?.timestamp || '0')).toLocaleString()} />
            <FilesDisplay source="uniqueid" />
        </SimpleShowLayout>
    </Show>
);

export default ApplicantView;
