import { Worksheet } from 'exceljs';

const columns = [
    {
        name: 'registerTime',
        label: 'Waktu Registrasi',
        columnWidth: 35,
    },
    {
        name: 'name',
        label: 'Nama Pendaftar',
        columnWidth: 20,
    },
    {
        name: 'email',
        label: 'Email',
        columnWidth: 27,
    },
    {
        name: 'job',
        label: 'Pekerjaan',
        columnWidth: 22,
    },
    {
        name: 'location',
        label: 'Lokasi',
        columnWidth: 15,
    },
    {
        name: 'phonenumber',
        label: 'Nomor Telepon',
        columnWidth: 20,
    },
    {
        name: 'files',
        label: 'Link File',
        columnWidth: 200,
    }
];

const initializeSheetColumns = (worksheet: Worksheet) => {
    // Initialize columns in worksheet
    worksheet.columns = columns.map(({ name, label, columnWidth }) => ({
        key: name,
        header: label,
        width: columnWidth,
    })) as any;    
};

export default initializeSheetColumns;
