import getProcessedApplicantData from './getProcessedApplicantData';
import generateWorkbook from './generateWorkbook';
import saveApplicantsWorkbook from './saveApplicantsWorkbook';
import addApplicantsToWorkbook from './addApplicantsToWorkbook';

const exporter = async (applicants) => {
    alert('Sedang proses download, mohon tunggu...');
    const applicantsWithLinks = await getProcessedApplicantData(applicants);
    // Initialize ExcelJS
    const workbook = generateWorkbook('IT Holywings');
    addApplicantsToWorkbook(workbook, applicantsWithLinks);
    await saveApplicantsWorkbook(workbook);
};

export default exporter;
