import { Worksheet } from 'exceljs';

const wrapTextStyle = {
    wrapText: true,
}
const boldFontStyle = {
    bold: true,
};

const reformatSheet = (worksheet: Worksheet) => {        
    // Display newlines in row
    worksheet.eachRow((row) => {
        const filesCell = row.getCell('files');
        filesCell.style.alignment = wrapTextStyle;
    });

    // Make first row bold
    worksheet.getRow(1).eachCell((cell) => { cell.style.font = boldFontStyle; });

    // Apply custom date format for registration time
    worksheet.getColumn('registerTime').eachCell((cell) => {
        cell.numFmt = 'dddd, dd mmmm yyyy\\ h:mm:ss\\ AM/PM'; // I think this is right ;-)
    });
}

export default reformatSheet;
