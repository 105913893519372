import { Workbook } from "exceljs";
import addApplicantsToMainSheet from './addApplicantsToMainSheet';
import addApplicantsByLocationToWb from './addApplicantsByLocationToWb';
import { Applicant } from "./Applicant";

const addApplicantsToWorkbook = (workbook: Workbook, applicants: Applicant[]) => {
    addApplicantsToMainSheet(workbook, applicants);
    addApplicantsByLocationToWb(workbook, applicants);
};

export default addApplicantsToWorkbook;
