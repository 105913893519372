// in app.js
import * as React from "react";
import { Admin, AuthProvider, Resource } from 'react-admin';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase-lazy-loading';
import ApplicantList from './ApplicantList';
import ApplicantView from "./ApplicantView";
// import { PostList, PostEdit, PostCreate, PostIcon } from './posts';

var firebaseConfig = {
  apiKey: "AIzaSyBYf0XZB7tVazpS6qZ8lAoWCSm5HFNeXak",
  authDomain: "holywings-recruitment-by-app.firebaseapp.com",
  databaseURL: "https://holywings-recruitment-by-app.firebaseio.com",
  projectId: "holywings-recruitment-by-app",
  storageBucket: "holywings-recruitment-by-app.appspot.com",
  messagingSenderId: "133752483032",
  appId: "1:133752483032:web:4ca6b281a01474a9093bad"
};

const authProvider = FirebaseAuthProvider(firebaseConfig, {
  persistence: 'session',
});

const dataProvider = FirebaseDataProvider(firebaseConfig, {
  // Enable logging of react-admin-firebase
  logging: true,
  dontAddIdFieldToDoc: false,
  associateUsersById: false,
  softDelete: false,
  metaFieldCasing: 'lower',
  disableMeta: false,
  lazyLoading: {
    enabled: true
  },
});

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider as any as AuthProvider}>
    <Resource
      name="applicants_batch2"
      list={ApplicantList}
      show={ApplicantView}
      options={{ label: 'Batch 2 Applicants' }}
    />
    <Resource
      name="applicants_batch1"
      list={ApplicantList}
      show={ApplicantView}
      options={{ label: 'Batch 1 Applicants' }}
    />
  </Admin>
);

export default App;
