import { Workbook } from 'exceljs';

const generateWorkbook = (author: string) => {
    const workbook = new Workbook();
    workbook.creator = author;
    workbook.lastModifiedBy = author;
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
}

export default generateWorkbook;
