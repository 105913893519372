import { Workbook } from 'exceljs';
import addApplicantsToSheet from './addApplicantsToSheet';
import { Applicant } from './Applicant';

const mainSheetName = 'Semua Pendaftar';

const addApplicantsToMainSheet = (workbook: Workbook, applicants: Applicant[]) => {
    addApplicantsToSheet(workbook, mainSheetName, applicants);
}

export default addApplicantsToMainSheet;
