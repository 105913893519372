import { Workbook } from 'exceljs';
import streamSaver from 'streamsaver';

declare global {
    interface Window {
        writer: any;
    }
}

const saveApplicantsWorkbook = async (workbook: Workbook) => {
    // Save files
    const excelFilename = `applicants_${(new Date()).toLocaleString()}.xlsx`;
    const excelJsBuffer = await workbook.xlsx.writeBuffer();
    const excelBlob = new Blob(
        [excelJsBuffer],
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
    );
    const fileStream = streamSaver.createWriteStream(excelFilename, {
        size: excelBlob.size,
    });
    const readableStream = excelBlob.stream();
    if (window.WritableStream && readableStream.pipeTo) {
        return readableStream.pipeTo(fileStream);
    }
    window.writer = fileStream.getWriter()
    const reader = readableStream.getReader()
    const pump = () => reader.read()
        .then(res => res.done
            ? window.writer.close()
            : window.writer.write(res.value).then(pump))

    pump()    
};

export default saveApplicantsWorkbook;
