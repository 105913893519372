import { Workbook } from 'exceljs';
import addApplicantsToSheet from './addApplicantsToSheet';
import { locationCodename } from '../../constants';
import _groupBy from 'lodash/groupBy';
import { Applicant } from './Applicant';

const addApplicantsByLocationToWb = (
    workbook: Workbook,
    applicants: Applicant[],
) => {
    const applicantsByLocation = _groupBy(applicants, 'locationCode');
    // Add sheet by location
    Object.entries(locationCodename).forEach((([code, location]) => {
        addApplicantsToSheet(workbook, location, applicantsByLocation[code] ?? []);
    }))    
}

export default addApplicantsByLocationToWb;
