// in posts.js
import * as React from "react";
import { List, Datagrid, Filter, SelectInput, TextField, FunctionField, Pagination } from 'react-admin';
import { locationCodename } from './constants';
import exporter from './helpers/exporter';

const DateFieldFromTimestamp = ({ record, source }: any) => (
    <span>{new Date(record?.[source]).toLocaleString()}</span>
);

const locationOptions = Object.entries(locationCodename).map((([key, value]) => ({
    id: key,
    name: value,
})))

const PostFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Location" source="location" alwaysOn choices={locationOptions} />
    </Filter>
);

const ApplicantPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ApplicantList = (props) => (
    <List {...props}
        title="Applicants"
        exporter={exporter}
        filters={<PostFilter />}
        sort={{ field: 'timestamp', order: 'DESC' }}
        perPage={50}
        pagination={<ApplicantPagination />}
    >
        <Datagrid rowClick="show">
            <TextField source="name" sortable={false} />
            <FunctionField label="Location"  render={record => locationCodename[record.location]} />
            <TextField source="job" sortable={false} />
            <DateFieldFromTimestamp source="timestamp" label="Time" />
        </Datagrid>
    </List>
);

export default ApplicantList;
