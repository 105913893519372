export const locationCodename = {
    JKT: "Jakarta",
    BGR: "Bogor",
    BKS: "Bekasi",
    TNG: "Tangerang",
    SMG: "Semarang",
    SBY: "Surabaya",
    MKS: "Makassar",
    BDG: "Bandung",
    MDN: "Medan",
};

export const firebaseConfig = {
    apiKey: "AIzaSyBYf0XZB7tVazpS6qZ8lAoWCSm5HFNeXak",
    authDomain: "holywings-recruitment-by-app.firebaseapp.com",
    databaseURL: "https://holywings-recruitment-by-app.firebaseio.com",
    projectId: "holywings-recruitment-by-app",
    storageBucket: "holywings-recruitment-by-app.appspot.com",
    messagingSenderId: "133752483032",
    appId: "1:133752483032:web:4ca6b281a01474a9093bad"
};

